import React from "react";
import { Switch } from "@headlessui/react";
import Label from "./Label";

const Toggle = ({
                  vertical,
                  label,
                  description,
                  checked,
                  onChange,
                  customCheckedColor="bg-am-600",
                  customUncheckedColor="bg-gray-200",
                  ...rest
                }) => {
  const switchDefaultClasses = `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-am-500`;
  const spanDefaultClasses = `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200`;

  return (
    <Switch.Group
      as="div"
      className={"flex items-left justify-between py-2 mt-3"}
    >
      {(label || description) && (
        <span className="grow flex flex-col">
          {label && (
            <Label
              as="span"
              className="text-sm font-medium text-gray-900"
              passive
            >
              {label}
            </Label>
          )}

          {description && (
            <p as="span" className="text-sm text-gray-500">
              {description}
            </p>
          )}
        </span>
      )}

      <Switch
        className={`${switchDefaultClasses} ${
          checked ? customCheckedColor : customUncheckedColor
        }`}
        checked={checked}
        onChange={onChange}
        {...rest}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${spanDefaultClasses} ${
            checked ? "translate-x-5" : "translate-x-0"
          }`}
        />
      </Switch>
    </Switch.Group>
  );
};

export default Toggle;
