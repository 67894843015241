const it_custom = {
  absolute_value: "Valore assoluto",
  add_products: "Aggiungi prodotti",
  add_taxonomy: "Aggiungi tassonomia",
  admin: "Amministratore",
  aggregate_fraudolent_customers: "Accessi anomali aggregati",
  aggregate_fraudolent_customers_explaination: "Informazioni aggregate sugli accessi anomali in un dato intervallo di tempo",
  base_viewer: "Utente base",
  code: "Codice",
  comparison_by_product: "Confronto per prodotto",
  comparison_by_year: "Confronto per anno",
  customer_journey: "Customer journey",
  customer_journey_KPI: "KPI customer journey",
  customers: "Clienti",
  date: "Data",
  detection_thresholds: "Soglie di rilevamento",
  devices: "Dispositivi",
  download_logs: "Download tracciati",
  end_date: "Data fine",
  fraud_detection: "Rilevamento accessi anomali",
  fraud_logs_last_months: "Tracciati comportamenti di accessi anomali negli ultimi 6 mesi",
  fraudolent_customers_by_product: "Accessi anomali per prodotto",
  fraudolent_customers_by_product_explaination: "Numero di accessi anomali raggruppati per mese e prodotto in un dato intervallo di tempo",
  fraudolent_customers_comparison_by_year: "Confronto accessi anomali per anno",
  fraudolent_customers_comparison_by_year_explaination: "Numero di accessi anomali di un prodotto specifico raggruppati per mese e confrontati tra gli ultimi due anni in un dato intervallo di tempo",
  fraudolent_customers_flow_comparison_by: "Confronto flussi accessi anomali per",
  fraudolents_from: "Accessi anomali da",
  frauds_viewer: "Visualizzatore accessi anomali",
  n_utenze_tot_m_1: "N users anomali",
  n_utenze_out_m: "N users anomali out",
  n_clienti_tot_m_1: "N clienti anomali",
  n_clienti_out_m: "N clienti anomali out",
  GC: "Grandi clienti",
  graph_type: "Tipo di grafico",
  ip: "IP",
  kpi_type: "Tipo di KPI",
  large_customers: "Grandi clienti",
  manager: "Manager",
  month: "Mese",
  months: "Mesi",
  no_fraudolent_users_time_interval: "Non ci sono accessi anomali per questo prodotto in questo intervallo di tempo",
  page_visible_only_to_admins: "Questa pagina è visibile solo agli amministratori, a scopo di test",
  percentage: "Percentuale",
  product: "Prodotto",
  product_category: "Categoria prodotto",
  products_and_taxonomy: "Prodotti & Tassonomie",
  products_list: "Lista prodotti",
  select_kpi: "Seleziona un KPI",
  select_one_product_from_list: "Seleziona un prodotto tra quelli disponibili",
  select_one_product_from_list_to_edit_taxonomy: "Seleziona un prodotto tra quelli disponibili per modifiarne le tassonomie",
  select_product: "Seleziona un prodotto",
  select_products: "Seleziona prodotti",
  select_product_category: "Seleziona una categoria prodotto",
  sessions: "Sessioni",
  ST: "Standard",
  standard_customers: "Clienti standard",
  start_date: "Data inizio",
  taxonomy: "Tassonomia",
  taxonomy_added_manually: "Tassonomia aggiunta manualmente",
  taxonomy_delete: "Elimina tassonomia",
  taxonomy_disabled_manually: "Tassonomia disabilitata manualmente",
  taxonomy_reenable: "Riattiva tassonomia",
  taxonomies: "Tassonomie",
  update: "Aggiorna",
  update_error: "Aggiornamento non riuscito",
  update_success: "Aggiornamento riuscito",
  user_type: "Tipo utente",
  year: "Anno",
};

export default it_custom;